import React from "react";
import { Gift } from "react-feather";
import CountUp from "react-countup";
import { Card, CardBody, Media } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";

const GET_PRODUCTS = gql`
	query products($status: String!, $start_date: String, $end_date: String) {
		products(status: $status, start_date: $start_date, end_date: $end_date) {
			total
		}
	}
`;
const TotalProduct = ({ startDate, endDate }) => {
	var { data } = useQuery(GET_PRODUCTS, {
		variables: {
			status: "active",
			start_date: moment(startDate).format("MM/DD/YYYY"),
			end_date: moment(endDate).format("MM/DD/YYYY"),
		},
	});
	return (
		<Card className="o-hidden widget-cards">
			<CardBody className="bg-danger">
				<Media className="static-top-widget row">
					<div className="icons-widgets col-2">
						<div className="align-self-center text-center">
							<Gift className="font-danger" />
						</div>
					</div>
					<Media body className="col-10">
						<span className="m-0">Total New Product</span>
						<h3 className="mb-0">
							&nbsp;&nbsp;
							<CountUp
								className="counter"
								end={data?.products?.total || 0} // Fallback to 0 if data is null or undefined
							/>
							<small> This Month</small>
						</h3>
					</Media>
				</Media>
			</CardBody>
		</Card>
	);
};

export default TotalProduct;
