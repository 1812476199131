import React, { useState, useEffect } from "react";
import {
	FormGroup,
	Form,
	Label,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { gql, useMutation } from "@apollo/client";

const UPDATE_IMAGE = gql`
	mutation updateTransferEvidenceRefund(
		$input: UpdateTransferEvidenceRefundInput!
	) {
		updateTransferEvidenceRefund(input: $input) {
			success
			message
		}
	}
`;

const ModalTransferEvidence = ({
	data,
	openModalDetail,
	setOpenModalDetail,
	refetch,
}) => {
	const [formValues, setFormValues] = useState({ id: "", file: null });
	const [updateTransferEvidenceRefund, { loading }] = useMutation(UPDATE_IMAGE);
	const [isDisabled, setIsDisabled] = useState(true);

	useEffect(() => {
		if (data) {
			setFormValues({ id: data.id, file: null });
		}
	}, [data]);

	const onCloseModal = () => {
		setOpenModalDetail(false);
		setFormValues({ id: "", file: null });
		setIsDisabled(true); // Reset state
	};

	const handleChange = (event) => {
		const { name, type, files } = event.target;
		const value = type === "file" ? files[0] : event.target.value;

		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));

		// Enable the button if the field is not empty
		setIsDisabled(!value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!formValues.file) {
			toast.error("Please select a file to upload.");
			return;
		}

		const input = { id: formValues.id, file: formValues.file };

		try {
			const result = await updateTransferEvidenceRefund({
				variables: { input },
			});
			if (result.data.updateTransferEvidenceRefund.success) {
				toast.success(result.data.updateTransferEvidenceRefund.message);
				refetch(); // Refresh data
				onCloseModal();
			} else {
				toast.error(result.data.updateTransferEvidenceRefund.message);
			}
		} catch (error) {
			toast.error("An error occurred while uploading the evidence.");
			console.error(error);
		}
	};

	return (
		<Modal isOpen={openModalDetail} toggle={onCloseModal}>
			<ModalHeader toggle={onCloseModal}>
				<h5 className="modal-title f-w-600">Bukti Transfer</h5>
			</ModalHeader>
			<Form onSubmit={handleSubmit} className="needs-validation">
				<ModalBody>
					{data && (
						<FormGroup>
							<div
								style={{ width: "100%", height: "320px", marginBottom: "16px" }}
							>
								<img
									src={
										data.transfer_evidence
											? `${process.env.REACT_APP_IMAGE_URL}${data.transfer_evidence}`
											: `${process.env.REACT_APP_IMAGE_URL}/upload/refund/default.jpg`
									}
									alt="Bukti Transfer"
									height="100%"
									className="d-block m-auto mw-100"
								/>
							</div>
							<Label className="mb-2">
								{data.transfer_evidence
									? "Edit Bukti Transfer"
									: "Upload Bukti Transfer"}
							</Label>
							<div>
								<Input
									className="form-control"
									id="file"
									name="file"
									type="file"
									accept="image/png, image/gif, image/jpeg"
									onChange={handleChange}
									required
								/>
							</div>
						</FormGroup>
					)}
				</ModalBody>
				<ModalFooter className="justify-content-end">
					<Button
						type="submit"
						color="primary"
						disabled={isDisabled || loading}
					>
						{loading ? "Uploading..." : "Submit"}
					</Button>
					<Button type="button" color="secondary" onClick={onCloseModal}>
						Close
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default ModalTransferEvidence;
