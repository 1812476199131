import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ModalDetailSaldoWithdrawRequest from "./modal-detail-saldo-withdraw-request";
import ModalTransferEvidence from "./modal-detail-tranfer-evidence";
import { numberFormat } from "../../../helpers/custom";
import Filter from "../../common/filter_components/filter";

const GET_SALDO_WITHDRAWAL_HISTORY = gql`
	query getAllSaldoWithdrawalRequestHistoryStore(
		$id: Int
		$stores_name: String
		$created_date: String
		$total: Int
		$status: String
		$start_date: String
		$end_date: String
		$sortBy: String
		$indexFrom: Int
		$limit: Int
	) {
		getAllSaldoWithdrawalRequestHistoryStore(
			id: $id
			stores_name: $stores_name
			created_date: $created_date
			total: $total
			status: $status
			start_date: $start_date
			end_date: $end_date
			sortBy: $sortBy
			indexFrom: $indexFrom
			limit: $limit
		) {
			total
			hasMore
			items {
				id
				total
				status
				stores_name
				created_date
				created_by
				accepted_date
				accepted_by
				done_date
				done_by
				reject_by
				reject_date
				reject_reason
				transfer_evidence
			}
		}
	}
`;

const TabsetProfile = () => {
	const sizePerPage = 10;
	const [detailId, setDetailId] = useState("");
	const [openModalDetail, setOpenModalDetail] = useState(false);
	const [detailData, setDetailData] = useState([]);
	const [openModalTransferEvidence, setOpenModalTransferEvidence] =
		useState(false);
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState({
		stores_name: "",
		total: "",
		date: { start: "", end: "" },
		status: "",
	});

	const [sortBy, setSortBy] = useState();

	const [defaultSorted, setDefaultSorted] = useState([
		{
			dataField: "id",
			order: "desc",
		},
	]);

	var { data, refetch } = useQuery(GET_SALDO_WITHDRAWAL_HISTORY, {
		variables: {
			indexFrom: page * sizePerPage - sizePerPage,
			limit: sizePerPage,
			id: filter && filter.id ? parseInt(filter.id) : null,
			stores_name: filter && filter.stores_name ? filter.stores_name : "",
			start_date: filter && filter.date ? filter.date.start : "",
			end_date: filter && filter.date ? filter.date.end : "",
			total: filter && filter.total ? parseInt(filter.total) : null,
			status: filter && filter.status ? filter.status.value : "",
			sortBy: sortBy,
		},
	});

	const columns = [
		{
			dataField: "id",
			text: "ID",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "10%" };
			},
		},
		{
			dataField: "stores_name",
			text: "Store",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "15%" };
			},
		},
		{
			dataField: "created_date",
			text: "Date",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "15%" };
			},
		},
		{
			dataField: "total",
			text: "Total",
			sort: true,
			formatter: (cell, row, rowIndex, extraData) =>
				numberFormat(parseInt(row.total)),
		},
		{
			dataField: "status",
			text: "Status",
			sort: true,
		},
		{
			dataField: "",
			text: "Bukti Transfer",
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn">
					{row.transfer_evidence !== null ? (
						<button
							type="button"
							className="btn btn-secondary text-capitalize"
							onClick={() => showModalTransferEvidence(row)}
						>
							Lihat
						</button>
					) : (
						<button
							type="button"
							className="btn btn-warning text-capitalize"
							onClick={() => showModalTransferEvidence(row)}
						>
							Upload
						</button>
					)}
				</div>
			),
		},
		{
			dataField: "",
			text: "",
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
					<Link to={`/saldo/withdraw/form/${row.id}`}>
						<i className="fa fa-edit" />
					</Link>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: "13%" };
			},
		},
	];

	const showModalDetail = (id) => {
		setDetailId(id);
		setOpenModalDetail(true);
	};

	const showModalTransferEvidence = (data) => {
		setDetailData(data);
		setOpenModalTransferEvidence(true);
	};

	const handleTableChange = async (
		e,
		{ filters, page: pages, sizePerPage, sortField, sortOrder, data }
	) => {
		if (pages) {
			setPage(pages);
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + "-" + sortOrder);
			setDefaultSorted([
				{
					dataField: sortField,
					order: sortOrder,
				},
			]);
		}
	};

	const handleFilterChange = (e) => {
		refetch({
			indexFrom: page * sizePerPage - sizePerPage,
			limit: sizePerPage,
			id: filter && filter.id ? parseInt(filter.id) : null,
			stores_name: filter && filter.stores_name ? filter.stores_name : "",
			start_date: filter && filter.date ? filter.date.start : "",
			end_date: filter && filter.date ? filter.date.end : "",
			total: filter && filter.total ? parseInt(filter.total) : null,
			status: filter && filter.status ? filter.status.value : "",
			sortBy: sortBy,
		});
		setFilter(e);
	};

	const filterOptions = [
		{
			id: "stores_name",
			label: "Store",
			value: filter.stores_name,
			type: "text",
		},
		{
			id: "date",
			label: "Date",
			value: filter.date,
			type: "date",
			className: "first-column",
		},
		{
			id: "total",
			label: "Total",
			value: filter.total,
			type: "text",
		},
		{
			id: "status",
			label: "Status",
			value: filter.status,
			type: "dropdown",
			options: [
				{
					label: "All",
					value: "",
				},
				{
					label: "Pending",
					value: "Pending",
				},
				{
					label: "Accept",
					value: "Accept",
				},
				{
					label: "Done",
					value: "Done",
				},
				{
					label: "Reject",
					value: "Reject",
				},
			],
		},
	];
	return (
		<>
			<h5 className="f-w-600 f-16 pull-left">Withdraw Request History</h5>
			<div className="clearfix"></div>
			<Filter
				filterOptions={filterOptions}
				filter={filter}
				handleFilterParentChange={handleFilterChange}
			/>
			<div
				id="batchDelete"
				className="category-table store-list order-table coupon-list-delete"
			>
				{data ? (
					<>
						<BootstrapTable
							keyField="id"
							hover
							condensed
							remote={{ sort: true, pagination: true }}
							data={
								data && data.getAllSaldoWithdrawalRequestHistoryStore
									? data.getAllSaldoWithdrawalRequestHistoryStore.items
									: []
							}
							columns={columns}
							bordered={false}
							noDataIndication="Table is Empty"
							pagination={paginationFactory({
								sizePerPage: sizePerPage,
								page: page,
								hideSizePerPage: true,
								hidePageListOnlyOnePage: true,
								totalSize:
									data && data.getAllSaldoWithdrawalRequestHistoryStore
										? data.getAllSaldoWithdrawalRequestHistoryStore.total
										: 0,
							})}
							defaultSorted={defaultSorted}
							onTableChange={handleTableChange}
							wrapperClasses="table-responsive"
						/>
						<ModalDetailSaldoWithdrawRequest
							id={detailId}
							openModalDetail={openModalDetail}
							setOpenModalDetail={setOpenModalDetail}
						/>
						<ModalTransferEvidence
							data={detailData}
							openModalDetail={openModalTransferEvidence}
							setOpenModalDetail={setOpenModalTransferEvidence}
							refetch={refetch}
						/>
					</>
				) : (
					""
				)}
			</div>
		</>
	);
};

export default TabsetProfile;
